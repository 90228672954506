import get from 'lodash.get';

export function getTranslations(
  localizations?: Record<string, Record<string, string>>
): Record<string, string> | null {
  if (!localizations) return null;

  return get(localizations, getLang(localizations));
}

function getLang(
  localizations: Record<string, Record<string, string>>
): string {
  const localizationKeys = Object.keys(localizations);
  if (localizationKeys.length > 0) return localizationKeys[0];
  return 'en';
}
